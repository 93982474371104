import React, { Component } from "react";
import { toast } from "react-toastify";
import ScannTable from "./scanntable";
import api from "../api";

export default class Scan extends Component {
  state = {
    cod: "",
    cod_temp: "",
    denumire: "",
    cantitate: null,
    cantImplicit: null,
    checked: false,
    mesaj: ["Scanati un articol!"],
    error: false,
  };

  componentDidMount() {
    this.focus();
  }
  onChangeCant = (e) => {
    //console.log(e.currentTarget.checked);
    this.setState(
      {
        checked: e.currentTarget.checked,
        cantImplicit: e.currentTarget.checked ? 1 : null,
      },
      () => this.focus()
    );
  };

  onChangeText = (e) => this.setState({ cod: e.currentTarget.value });
  handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      const mesaj = [...this.state.mesaj];
      mesaj.splice(2, 1);
      this.setState({ mesaj: mesaj });
      this.setState({ cod_temp: this.state.cod });
      api
        .post("/inventarV1", {
          code: this.state.cod,
          quantity: this.state.cantImplicit,
        })
        .then((response) => {
          console.log(response.data);
          if (response.data.error === true) {
            this.focus();
            this.setState({
              error: true,
              mesaj: [response.data.message, ...this.state.mesaj],
            });
            return toast.error(response.data.message, {
              position: toast.POSITION.BOTTOM_CENTER,
            });
          }

          this.setState({
            cod: response.data.message.CodExtern,
            denumire: response.data.message.Denumire,
            cantitate: response.data.message.Cantitate,
          });
          if (response.data.hasCantImp === true) {
            this.setState(
              {
                mesaj: [
                  `
          Cod: ${response.data.message.CodExtern}~
          Denumire: ${response.data.message.Denumire}~
          Cantitate totala: ${response.data.message.Cantitate}~ 
          Scanat: ${this.state.cantImplicit}
          `,
                  ...this.state.mesaj,
                ],
              },
              () =>
                toast.success("Articol actualizat cu succes!", {
                  position: toast.POSITION.BOTTOM_CENTER,
                })
            );
            this.focus();
          }
        })
        .catch((error) => {
          console.log(error);
          this.focus();
          this.setState({
            mesaj: [error.data.message.error, ...this.state.mesaj],
            error: true,
          });
          return toast.error("Eroare...Incercati sa va reconectati!", {
            position: toast.POSITION.BOTTOM_CENTER,
          });
        });
    }
  };

  handleKeySave = (e) => {
    if (e.keyCode === 13) {
      const mesaj = [...this.state.mesaj];
      mesaj.splice(2, 1);
      this.setState({ mesaj: mesaj });
      if (!e.currentTarget.value) {
        return toast.error("Cantitate eroanata!", {
          position: toast.POSITION.BOTTOM_CENTER,
        });
      }
      if (e.currentTarget.value > 9999) {
        return toast.error(
          "Cantitate prea mare!...Posibil ati scanat cod de bare in cantitate",
          {
            position: toast.POSITION.BOTTOM_CENTER,
          }
        );
      }
      let cant = e.currentTarget.value;
      api
        .post("/inventarV1", {
          code: this.state.cod,
          quantity: cant,
        })
        .then((response) => {
          this.setState(
            {
              mesaj: [
                `
        Cod: ${response.data.message.CodExtern}~
        Denumire: ${response.data.message.Denumire}~
        Cantitate totala: ${response.data.message.Cantitate}~
        Scanat: ${cant}
        `,
                ...this.state.mesaj,
              ],
            },
            () =>
              toast.success("Articol actualizat cu succes!", {
                position: toast.POSITION.BOTTOM_CENTER,
              })
          );

          this.focus();
        })
        .catch((error) => {
          console.log(error);
          this.focus();
          this.setState({
            mesaj: [error.data.message.error, ...this.state.mesaj],
            error: true,
          });
          return toast.error("Eroare...Incercati sa va reconectati!", {
            position: toast.POSITION.BOTTOM_CENTER,
          });
        });
    }
  };

  textInput = React.createRef();

  focus = () => {
    this.textInput.current.focus();
    this.setState({ cod: "", denumire: "", cantitate: "", error: false });
  };

  render() {
    return (
      <div className="container">
        <div className="pt-4 my-md-5 pt-md-5 custom-control custom-checkbox mb-3">
          <input
            type="checkbox"
            className="custom-control-input"
            id="customControlValidation1"
            checked={this.state.checked}
            onChange={this.onChangeCant}
          />
          <label
            className="custom-control-label"
            htmlFor="customControlValidation1"
          >
            Cantitate implicita:
            {this.state.cantImplicit ? "  1" : "  Nedefinit"}
          </label>
        </div>

        <label htmlFor="inputScan">Scaneaza articole:</label>

        <input
          type="text"
          className="form-control"
          id="inputScann"
          ref={this.textInput}
          placeholder="cod articol"
          onChange={this.onChangeText}
          onKeyDown={this.handleKeyDown}
          value={this.state.cod}
        ></input>
        <ScannTable
          cod={this.state.cod_temp}
          denumire={this.state.denumire}
          cantitate={this.state.cantitate}
          cantImp={this.state.cantImplicit}
          mesaj={this.state.mesaj}
          error={this.state.error}
          onKeyDown={this.handleKeySave}
        />
      </div>
    );
  }
}
