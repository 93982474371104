import React from "react";

let ScannTable = (props) => {
  return (
    <div className="pt-4 my-md-5 pt-md-5">
      {props.denumire.length > 0 && props.cantImp === null ? (
        <React.Fragment>
          <table className="table table-curved">
            <thead>
              <tr>
                <th scope="col">Cod</th>
                <th scope="col">{props.cod}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>Denumire</th>
                <th>{props.denumire}</th>
              </tr>
              <tr>
                <th>Cantitate</th>
                <th>{props.cantitate}</th>
              </tr>
              <tr>
                <th>Faptic</th>
                <th>
                  <input
                    type="text"
                    placeholder="cant. scriptic"
                    autoFocus
                    defaultValue={props.cantImp}
                    onChange={(e) => {
                      if (isNaN(e.currentTarget.value)) {
                        e.currentTarget.value = "";
                      }
                    }}
                    onKeyDown={props.onKeyDown}
                  ></input>
                </th>
              </tr>
            </tbody>
          </table>
        </React.Fragment>
      ) : (
        <React.Fragment>
          {/* <span>
            {props.mesaj.map((item, key) =>
              key === 0 ? (
                <div style={{ color: "red" }} key={key}>
                  {item}
                </div>
              ) : (
                <div key={key}>{item}</div>
              )
            )}
            </span> */}
          <span>Ultimele actiuni facute:</span>
          <ul className="list-group mb-3">
            {props.mesaj.map((item, key) =>
              key === 0 ? (
                <li
                  className="list-group-item d-flex justify-content-between bg-light"
                  key={key}
                >
                  <div className="text-warning">
                    <span className="my-0">{item}</span>
                  </div>
                </li>
              ) : (
                <li
                  className="list-group-item d-flex justify-content-between lh-condensed"
                  key={key}
                >
                  <span>{item}</span>
                </li>
              )
            )}
          </ul>
        </React.Fragment>
      )}
    </div>
  );
};

export default ScannTable;
