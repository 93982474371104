import React, { Component } from "react";
import { ToastContainer } from "react-toastify";
import AppLogin from "./components/AppLogin";

import "bootstrap/dist/css/bootstrap.css";
import "react-toastify/dist/ReactToastify.css";
import "./style.css";

//const TestComponent = props => <h1>10 lei</h1>;
export default class App extends Component {
  render() {
    return (
      <div>
        <AppLogin />
        <ToastContainer autoClose={2000} />
      </div>
    );
  }
}
