import React, { Component } from "react";
import { ToastContainer } from "react-toastify";
import { Redirect, Switch, Route } from "react-router-dom";
import Login from "./login";
import Navbar from "./Navbar";
import getRoute from "../services/routeRender";
import api from "../api";
//const TestComponent = props => <h1>10 lei</h1>;
export default class AppLogin extends Component {
  render() {
    return (
      <div className="section-main">
        <Switch>
          <Route path="/login" component={Login} />
          <Route path="/api" component={Api} />
          <Redirect from="/" to="/login" />
        </Switch>
        <ToastContainer autoClose={2000} />
      </div>
    );
  }
}

class Api extends React.Component {
  state = {
    modules: [],
    nameModules: [],
    username: "",
    firma: "",
  };

  async componentDidMount() {
    try {
      const { data } = await api.get("/module");
      this.setState({
        modules: data[0].path,
        nameModules: data[0].name,
        username: data[0].username,
        firma: data[0].firma,
      });
      //console.log(this.state.nameModules);
    } catch (error) {
      console.log(error);
    }
  }

  render() {
    return (
      <React.Fragment>
        <Navbar
          modules={this.state.modules}
          nameModules={this.state.nameModules}
          username={this.state.username}
          firma={this.state.firma}
        />
        <Switch>
          {this.state.modules.map((m) => (
            <Route key={m} path={`/api${m}`} component={getRoute(m)} />
          ))}
        </Switch>
      </React.Fragment>
    );
  }
}
