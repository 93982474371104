import React, { Component } from "react";
import { Image } from "react-bootstrap";
import img_logo from "../assets/logo_inv.png";

export default class Navbar extends Component {
  render() {
    return (
      <nav className="navbar navbar-expand-sm  navbar-dark justify-content-end bg-info">
        <a className="navbar-brand" href="/inventar">
          <Image src={img_logo} width="50" />
        </a>
        <div className="navbar-nav ml-auto nav-bar-name">
          {this.props.username}/{this.props.firma}
        </div>
        <a href="/login" className="btn btn-sm btn-light ml-auto mr-2">
          {" "}
          Log-out
        </a>
      </nav>
    );
  }
}
