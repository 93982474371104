import Scan from "../components/scann";
import Config from "../components/Config";
import Export from "../components/Export";

export default function getRoute(path) {
  switch (path) {
    case "/inventar":
      return Scan;
    case "/export":
      return Export;
    default:
      return Config;
  }
}
