import React, { Component } from "react";

import api from "../api";
class Config extends Component {
  state = {
    itemUserConfig: [],
    itemGestiuni: [],
    itemTipCont: [],
    codIdentificareArticol: "CodExtern",
  };

  componentDidMount = () => {
    api
      .get("/setConfigUsers")
      .then((response) => {
        //console.log(response.data);

        this.setState({
          itemUserConfig: response.data.dataOrient,
          itemGestiuni: response.data.gestiuni,
          itemTipCont: response.data.tipuriContabile,
        });
        //console.log(this.state);
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  clickSave = (e, uuid) => {
    const uuidLine = uuid;
    const selGestiune = this.state.itemUserConfig[e.currentTarget.value]
      .selGestiune;
    console.log(selGestiune);
    const selTipCont = this.state.itemUserConfig[e.currentTarget.value]
      .selTipCont;
    console.log(selTipCont);
    api
      .post("/save", {
        uuidLine: uuidLine,
        param: { gestiune: selGestiune, tipContabil: selTipCont },
      })
      .then((response) => {
        console.log(response.data);
        //this.setState({ itemUserConfig: response.data });
      })
      .catch((error) => {
        console.log(error.message);
      });
    window.location.reload(true);
  };
  clickReset = (e, uuid) => {
    const uuidLine = uuid;
    api
      .post("/reset", { uuidLine: uuidLine })
      .then((response) => {
        console.log(response.data);
        //this.setState({ itemUserConfig: response.data });
      })
      .catch((error) => {
        console.log(error.message);
      });
    window.location.reload(true);
  };

  handleOnChangeSelect = (e, itemUuid) => {
    const itemUserConfig = [...this.state.itemUserConfig];

    const item = itemUserConfig.filter((i) => i.uuid === itemUuid)[0];

    item[e.currentTarget.id] = e.currentTarget.value;

    this.setState({ itemUserConfig });
  };
  handleCodScann = (e) => {
    this.setState({ codIdentificareArticol: e.currentTarget.value });
  };
  clickArticle = (e, uuid) => {
    const uuidLine = uuid;
    api
      .post("/loadInventar", {
        uuidLine: uuidLine,
        codArt: this.state.codIdentificareArticol,
      })
      .then((response) => {
        console.log(response.data);
        //this.setState({ itemUserConfig: response.data });
      })
      .catch((error) => {
        console.log(error.message);
      });
  };
  render() {
    console.log(this.state.itemUserConfig);
    console.log(this.state.codIdentificareArticol);
    return (
      <div className="table-responsive">
        <table className="table table-striped">
          <thead>
            <tr>
              <th>#</th>
              <th>codIdentificareArticole</th>
              <th>
                <select
                  className="form-control"
                  id="selCodArticol"
                  defaultValue={"CodExtern"}
                  onChange={this.handleCodScann}
                >
                  <option value="CodExtern">CodExtern</option>
                  <option value="CodIntern">CodIntern</option>
                </select>
              </th>
            </tr>
          </thead>
        </table>
        <table className="table table-striped">
          <thead>
            <tr>
              <th>#</th>
              <th>UseName</th>
              <th>Gestiune</th>
              <th>SetGestiune</th>
              <th>TipContabil</th>
              <th>SetTipContabil</th>
              <th>Save</th>
              <th>Reset</th>
              <th>addArticle</th>
            </tr>
          </thead>

          <tbody>
            {this.state.itemUserConfig.map((item, key) => (
              <tr className="table-info" key={key}>
                <th key={key}>{key}</th>
                <td key={`${key}1`}>{item.username}</td>
                <td key={`${key}2`}>{item.gestiune}</td>
                <td key={`${key}3`}>
                  <select
                    className="form-control"
                    id="selGestiune"
                    defaultValue={"nedefinit"}
                    onChange={(e) => {
                      this.handleOnChangeSelect(e, item.uuid);
                    }}
                  >
                    <option value="nedefinit" disabled>
                      Alege Gestiune
                    </option>
                    {this.state.itemGestiuni.map((item, key) => (
                      <option key={key} value={item.denGest}>
                        {item.denGest}
                      </option>
                    ))}
                  </select>
                </td>
                <td key={`${key}4`}>{item.tipContabil}</td>
                <td key={`${key}5`}>
                  <select
                    className="form-control"
                    id="selTipCont"
                    defaultValue={"nedefinit"}
                    onChange={(e) => {
                      this.handleOnChangeSelect(e, item.uuid);
                    }}
                  >
                    <option value="nedefinit" disabled>
                      Alege TipContabil
                    </option>
                    {this.state.itemTipCont.map((item, key) => (
                      <option key={key} value={item.tipCont}>
                        {item.tipCont}
                      </option>
                    ))}
                  </select>
                </td>
                <td key={`${key}6`}>
                  <button
                    type="submit"
                    name="Save"
                    className="btn btn-info btn-block"
                    value={key}
                    onClick={(e) => this.clickSave(e, item.uuid)}
                    disabled={false}
                  >
                    Save
                  </button>
                </td>
                <td key={`${key}7`}>
                  <button
                    type="submit"
                    name="Reset"
                    className="btn btn-danger btn-block"
                    value={key}
                    onClick={(e) => this.clickReset(e, item.uuid)}
                  >
                    Reset
                  </button>
                </td>
                <td>
                  <button
                    type="submit"
                    name="addArticle"
                    className="btn btn-danger btn-block"
                    value={key}
                    onClick={(e) => this.clickArticle(e, item.uuid)}
                  >
                    addArticle
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}

export default Config;
