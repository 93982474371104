import React, { Component } from "react";
import { toast } from "react-toastify";
import { Button, Col, Row, Form } from "react-bootstrap";
import api from "../api";

export default class Login extends Component {
  state = {
    isAdmin: false,
    loginMessage: "Introduceti user si parola!",
    classMessage: "info",
    loginStatus: false,
    login: {
      userLogin: "",
      passLogin: "",
    },
  };

  componentDidMount() {
    this.focus();
  }
  textInput = React.createRef();

  focus = () => {
    this.textInput.current.focus();
    let login = { ...this.state.login };
    //console.log(login);
    Object.keys(login).map((key) => (login[key] = ""));
    this.setState({ login });
  };

  change = (e) => {
    const login = { ...this.state.login };
    login[e.currentTarget.id] = e.currentTarget.value;

    this.setState({ login });
    //console.log(this.state.login);
  };
  click = (e) => {
    api
      .post("/login", {
        user: this.state.login.userLogin,
        password: this.state.login.passLogin,
      })
      .then((response) => {
        //console.log(response.data);
        if (response.status === 200) {
          this.setState({
            loginMessage: "Date de identificare verificate!",
            loginStatus: true,
            isAdmin: response.data.isAdmin,
          });
          localStorage.setItem("token", response.data.token);
          // if (this.state.isAdmin) {
          //   return window.location.replace("/nav");
          // }
          // return window.location.replace("/inventar");

          const newPath = JSON.parse(response.data.navItems)[0].path;

          return window.location.replace(`/api${newPath}`);
        }
      })
      .catch((error) => {
        this.focus();
        //console.log(error);
        this.setState({
          loginMessage: "Date de identificare eronate!",
          classMessage: "danger",
        });
        return toast.error("Date de identificare eronate!", {
          position: toast.POSITION.BOTTOM_CENTER,
        });
      });
  };

  render() {
    return (
      <div>
        <React.Fragment>
          <Row>
            <Col>
              <div className="form-signin">
                <img alt="logo" src="logo_inv.png" width="50" />
                <p className="mb-1 font-weight-light">
                  Va rugam sa va autentificati in
                </p>
                <h1 className="mb-3 font-weight-normal">
                  Inventar WinMentor PDX
                </h1>
                <div
                  className={`alert alert-${this.state.classMessage} text-center`}
                >
                  {this.state.loginMessage}
                </div>
                <Form.Group>
                  <input
                    type="text"
                    id="userLogin"
                    ref={this.textInput}
                    className="form-control"
                    name="userLogin"
                    placeholder="User"
                    onChange={this.change}
                    value={this.state.login.userLogin}
                  />
                </Form.Group>
                <Form.Group>
                  <input
                    type="password"
                    id="passLogin"
                    className="form-control"
                    name="passLogin"
                    placeholder="Parola"
                    onChange={this.change}
                    value={this.state.login.passLogin}
                  />
                </Form.Group>
                <Button
                  type="submit"
                  name="submit"
                  className="btn btn-info btn-block"
                  onClick={this.click}
                >
                  Autentificare
                </Button>
                <p className="mt-4 text-muted">
                  © 2020 <img src="logo.png" alt="" width="50" />{" "}
                </p>
              </div>
            </Col>
          </Row>
          {/* <div className="row">
            <div className="col-md-10 col-md-offset-10">
              <div className="login-panel panel panel-default">
                <div className="panel-heading bg-info rounded">
                  <legend className="text-center">
                    Login Inventar WinMentor PDX
                  </legend>
                  <div
                    className={`alert alert-${this.state.classMessage} form-control text-center`}
                  >
                    {this.state.loginMessage}
                  </div>
                </div>
                <div className="panel-body bg-info rounded">
                  <legend>
                    <input
                      type="text"
                      id="userLogin"
                      ref={this.textInput}
                      className="form-control"
                      name="userLogin"
                      placeholder="Username"
                      onChange={this.change}
                      value={this.state.login.userLogin}
                    />
                  </legend>
                  <legend>
                    <input
                      type="password"
                      id="passLogin"
                      className="form-control"
                      name="passLogin"
                      placeholder="Password"
                      onChange={this.change}
                      value={this.state.login.passLogin}
                    />
                  </legend>
                  <button
                    type="submit"
                    name="submit"
                    className="btn btn-info btn-block"
                    onClick={this.click}
                  >
                    Sign in
                  </button>
                </div>
                
              </div>
            </div>
          </div> */}
        </React.Fragment>
      </div>
    );
  }
}
